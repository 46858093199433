import React, { Component } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/Layout"
import Container from "../components/Container"
import SEO from "../components/Seo"
import Button from "../components/Button"

import "../styles/pages/homepage.scss"

class HomePage extends Component {
  render() {
    console.log("Develop by in.zakiy@gmail.com")
    const { data } = this.props
    const {
      site: { siteMetadata },
      homepageBanner,
      crudePalmOilImg,
      rbdPalmOilImg,
      tropicalFruitImg,
      beansProductsImg,
    } = data

    return (
      <Layout>
        <SEO
          title="Home"
          description={`${siteMetadata.description}`}
          keywords={[
            "tredo",
            "trading",
            "company",
            "jakarta",
            "indonesia",
            "agriculture",
            "crude",
            "palm",
            "oil",
          ]}
          image={
            homepageBanner ? homepageBanner.childImageSharp.resize : undefined
          }
        />
        <div className="homepage">
          <div className="homepage__banner">
            <Container>
              <p>
                Welcome to Tredo Trading Company! Trusted Hub of products from
                Indonesia with capability to import and export worldwide. We are
                a general trading company, specializing in agriculture products.
                As your trusted trading partner, we can offer you a wide range
                of expertise in marketing, sales, delivery and distribution of
                your products and services. We are ready to assist producer to
                enter market for both local and international and at the same
                time we are ready to support buyer in delivering particular
                product or service from Indonesia. <br />
                <br />
                We are a TRUSTED Partner To Achieve Your Goal.
              </p>
              <Button
                className="mt-32"
                tag="a"
                size="large"
                href="mailto:info@tredo.co.id"
              >
                CONTACT US
              </Button>
            </Container>
            <Img
              className="homepage__banner-bg"
              fluid={homepageBanner.childImageSharp.fluid}
            />
          </div>

          <Container className="homepage__products mt-64">
            <h1>Products</h1>
            <div className="homepage__products-wrapper">
              <div className="homepage__products-card">
                <Img fluid={crudePalmOilImg.childImageSharp.fluid} />
                <p className="mt-16">Crude Palm Oil</p>
              </div>
              <div className="homepage__products-card">
                <Img fluid={rbdPalmOilImg.childImageSharp.fluid} />
                <p className="mt-16">RBD Palm Oil</p>
              </div>
              <div className="homepage__products-card">
                <Img fluid={tropicalFruitImg.childImageSharp.fluid} />
                <p className="mt-16">Tropical Fruits</p>
              </div>
              <div className="homepage__products-card">
                <Img fluid={beansProductsImg.childImageSharp.fluid} />
                <p className="mt-16">Agriculture Products</p>
              </div>
            </div>
          </Container>

          <Container className="homepage__services mt-64">
            <h1>Services</h1>
            <div className="homepage__services-line"></div>
            <p>
              Our Commitment is to keep our client happy, satisfied and trustful
              with our reliable team.
            </p>
            <ul>
              <li>
                We keep our client satisfied by following their shipment until
                it reaches them
              </li>
              <li>
                We ensure to dispatch your shipment / goods as fast as possible
              </li>
              <li>
                We ensure to dispatch your shipment / goods as fast as possible
              </li>
              <li>
                We also advice our business partners with the best trading
                information to move their business ahead towards success
              </li>
              <li>
                We can also issue any kind of trade insurance on your behalf
              </li>
              <li>We can give you excellent payment terms and conditions</li>
            </ul>
          </Container>

          <Container className="homepage__services mt-64">
            <h1>Partnership</h1>
            <div className="homepage__services-line"></div>
            <p>
              With Tredo as your trusted partner in value-added distribution,
              marketing and agency representation for one of the world’s most
              dynamic and growing markets. <br />
              <br /> We are looking for international partners who wants to
              promote their products in Indonesia and South East Asia Region. We
              may work for you as a regional distributors. Tredo provides import
              representation and strategic marketing assistance to companies
              that have identified the Indonesia and South East Asia Region as a
              core target for their quality products and brands. <br />
              <br /> Currently, we are inviting manufacturers of products and
              brands to be our partners. Our partners benefit from:
            </p>
            <ul>
              <li>International networks</li>
              <li>Specialist marketing advice</li>
              <li>Established distribution channels</li>
              <li>
                Efficient administrative support to secure regulatory approvals
              </li>
            </ul>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default HomePage

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    homepageBanner: file(relativePath: { eq: "images/tredo-bg.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
        resize(width: 1000) {
          src
          height
          width
        }
      }
    }
    crudePalmOilImg: file(relativePath: { eq: "images/crude-palm-oil.png" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    rbdPalmOilImg: file(relativePath: { eq: "images/rbd-palm-oil.png" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    tropicalFruitImg: file(relativePath: { eq: "images/tropical-fruit.png" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    beansProductsImg: file(relativePath: { eq: "images/beans-products.png" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
